<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <img src="img/logo-dark.png" class="c-avatar-img d-lg-none" />
                <h1 v-show="notfound">Gracias por preferinos</h1>
                <img v-show="notfound" src="img/boxes.png" alt="" style="width: 100%" class="d-lg-none">
                <CForm v-show="!notfound">
                  <h1>Completa tu orden</h1>
                  <p class="text-muted">{{ cart.name }}</p>
                  <p class="text-muted">{{ cart.email }}</p>
                  <CRow v-if="alert.show">
                    <Ccol>
                      <CAlert :color="alert.color" dismissible>
                        {{ alert.message }}
                      </CAlert>
                    </Ccol>
                  </CRow>
                  <CDataTable hover striped :items="products" :fields="fields">
                    <template #Price="data">
                      <td>
                        {{ formatPrice(data.item.Price) }}
                      </td>
                    </template>
                  </CDataTable>
                  <p style="text-align: right;">
                    Subtotal
                    <span class="prices"
                      >${{ formatPrice(cart.taxbaseamount) }}</span
                    >
                  </p>
                  <p style="text-align: right;">
                    Impuesto
                    <span class="prices"
                      >${{
                        formatPrice(cart.total - cart.taxbaseamount)
                      }}</span
                    >
                  </p>
                  <p style="text-align: right;">
                    Total
                    <span class="prices"
                      >${{ formatPrice(cart.total) }}</span
                    >
                  </p>
                  <CRow v-show="!complete">
                    <CCol col="12" class="text-right">
                      <CButton
                        class="js-payment-checkout"
                        color="info"
                        @click="payment()"
                      >
                        <font-awesome-icon icon="handshake" v-if="!loading" />
                        <CSpinner v-if="loading" variant="grow" size="sm" />
                        Continuar con el pago
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-1 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <img src="img/logo.png" class="c-avatar-img" />
                
                <img src="img/boxes.png" alt="" style="width: 100%">
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    cart: {},
    loading: false,
    complete: false,
    notfound: false,
    alert: {
      color: "danger",
      message: "success",
      show: false,
    },
    products: [],
    fields: [
      { key: "ItemName", label: "Nombre" },
      { key: "quantity", label: "Cantidad" },
      { key: "Price", label: "Precio" },
    ],
  }),
  async mounted() {
    await axios
      .get(process.env.VUE_APP_BACKEND_URL+"/api/pay/" + this.$route.params.id)
      .then((response) => {
        console.log(response);
        this.cart = response.data.cart;
        this.products = response.data.items;
        if (!this.cart.id) {
          this.notfound = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    let paymentezScript = document.createElement("script");
    paymentezScript.setAttribute(
      "src",
      "https://cdn.paymentez.com/ccapi/sdk/payment_checkout_stable.min.js"
    );
    paymentezScript.setAttribute("charset", "UTF-8");
    document.head.appendChild(paymentezScript);

    let paymentezStyle = document.createElement("link");
    paymentezStyle.setAttribute(
      "href",
      'https://cdn.paymentez.com/ccapi/sdk/payment_stable.min.css" rel="stylesheet'
    );
    paymentezStyle.setAttribute("rel", "stylesheet");
    paymentezStyle.setAttribute("type", "text/css");
    document.head.appendChild(paymentezStyle);

    window.addEventListener("popstate", function () {
      paymentCheckout.close();
    });
    window.complete = this;
  },
  methods: {
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    payment() {
      this.loading = true;
      var paymentCheckout = new PaymentCheckout.modal({
        client_app_code: process.env.VUE_APP_PAYMENTEZ_CODE,
        client_app_key: process.env.VUE_APP_PAYMENTEZ_KEY,
        locale: "es",
        env_mode: process.env.VUE_APP_PAYMENTEZ_ENV,
        onOpen: function () {
          //console.log('modal open');
        },
        onClose: function () {
          this.loading = false;
        },
        onResponse: async function (response) {
          console.log(response);
          if (response.transaction.status === "success") {
            await axios
              .post(process.env.VUE_APP_BACKEND_URL+"/api/complete", response)
              .then((response) => {
                console.log(response);
                window.complete.loading = false;
                window.complete.complete = true;
                window.complete.alert.color = "success";
                window.complete.alert.message =
                  "Muchas gracias por completar su transaccion en breve recibira un correo";
                window.complete.alert.show = true;
              })
              .catch((error) => {
                console.log(error);
                window.complete.loading = false;
                window.complete.alert.color = "danger";
                window.complete.alert.message =
                  "No se pudo completar la transaccion";
                window.complete.alert.show = true;
              });
          } else {
            window.complete.alert.color = "danger";
            window.complete.alert.message = response.transaction.message;
            window.complete.alert.show = true;
          }
        },
      });
      paymentCheckout.open({
        user_id: "1",
        user_email: this.cart.email,
        user_phone: this.cart.phone,
        order_description: this.cart.address,
        order_amount: this.roundToTwo(this.cart.total),
        order_vat: this.roundToTwo(this.cart.total - this.cart.taxbaseamount),
        //order_installments_type: 0, //0 corriente, 2 diferido con intereses, 3 diferido sin intereses
        order_taxable_amount: this.roundToTwo(this.cart.taxbaseamount),
        order_tax_percentage: 15,
        order_reference: "#" + this.cart.id,
      });
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
  },
  name: "Login",
};
</script>
